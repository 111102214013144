export const homeObjOne = {
    primary: true,
    lightBg: false,
    lightTopLine: true,
    lightText: false,
    lightTextDesc: true,
    topLine: 'iOS, Android',
    headline: '수험생 키우기',
    description:
      '아무렇게나 살면 아무 대학이나 간다!\n' +
        '‘K-고삼’이 되어 전략을 세우고 대학 입시에 도전하세요!\n' +
        '\n' +
        '물론 대학에 모두 떨어질 경우, 강제로 재수학원에 들어가니 조심하세요.\n' +
        '\n' +
        '매 순간의 판단이 여러분의 운명을 결정합니다...',
    buttonLabel: 'About',
    imgStart: '',
    img: require('../../images/banner1.png'),
    alt: 'iPhoneMock',
    start: ''
  };