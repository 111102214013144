import React from "react";
import { FaGooglePlay, FaAppStoreIos } from "react-icons/fa";
import { FooterContainer, FooterLinksContainer, FooterLinksWrapper, FooterLinkItems, FooterLinkTitle, FooterLink, SocialMedia, SocialMediaWrap, SocialLogo, SocialIcon, WebsiteRights, SocialIcons, SocialIconLink } from "./Footer.elements";

function Footer() {
  const date = new Date();

  return (
    <FooterContainer>
      <SocialMedia>
        <SocialMediaWrap>
          <WebsiteRights>Basakansoft © {date.getFullYear()}&nbsp;&nbsp; | &nbsp;&nbsp;official@basakansoft.com </WebsiteRights>
        </SocialMediaWrap>
      </SocialMedia>
    </FooterContainer>
  );
}

export default Footer;
