import styled from "styled-components";

export const CenterWrapper = styled.div`
  margin: 5rem 0;
  color: black;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;

  & h1 {
      color: black;
  }

  & img {
    width: 15rem;
    margin: 0 auto;
  }
`;
